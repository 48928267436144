@import url("https://fonts.googleapis.com/css?family=Exo");
html {
  font-family: "Exo", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Exo", sans-serif; }

body {
  background: #efefef; }

.no-pad {
  padding: 0 !important; }

.subtle {
  font-family: "Exo", sans-serif;
  color: #666;
  font-stretch: expanded; }

.logo {
  font-size: 2.4rem !important; }
