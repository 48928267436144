@import url(https://fonts.googleapis.com/css?family=Exo);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-family: "Exo", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Exo", sans-serif; }

body {
  background: #efefef; }

.no-pad {
  padding: 0 !important; }

.subtle {
  font-family: "Exo", sans-serif;
  color: #666;
  font-stretch: expanded; }

.logo {
  font-size: 2.4rem !important; }

